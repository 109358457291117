interface CacheElement {
  (key: string): string;
  keys: () => string[];
}

interface Dic {
  [key: string]: string;
}

const imageDic: Dic = {};
const cardDic: Dic = {};

const importAssets = (r: CacheElement, dic: Dic) => {
  r.keys().forEach((key) => (dic[key] = r(key)));
};

importAssets(require.context("Images/", true, /\.jpg|.png|.svg$/), imageDic);
importAssets(require.context("Cards/", true, /\.jpg|.png|.pdf$/), cardDic);

export const images: Dic = { ...imageDic };
export const cards: Dic = { ...cardDic };
