import React, { useCallback, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { PosterCategories } from "Types";
import { Gestures, Position } from "Gestures";
import styles from "./index.module.scss";
import chevron from "Images/poster-chevron.svg";

interface Props {
  categories: PosterCategories;
}

const hexToRGB = (h: string, a: number) => {
  let r = "0",
    g = "0",
    b = "0";

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgb(" + +r + "," + +g + "," + +b + "," + a + ")";
};

const Posters: React.FC<Props> = ({ categories }: Props) => {
  const [opened, setOpened] = useState<number[]>([]);
  const [right, setRight] = useState(0);
  const scroller = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const channel = useRef<HTMLDivElement>(null);
  const bar = useRef<HTMLDivElement>(null);
  const startTime = useRef(0);
  const y0 = useRef(0);
  const prevCategories = useRef(categories);
  const prevOpened = useRef(opened);
  let hasAbstracts = false;

  for (const category of Object.values(categories)) {
    for (const poster of category.posters) {
      if (poster.abstract !== undefined) {
        hasAbstracts = true;
      }
    }
  }

  const suffix = hasAbstracts ? "with-abstract" : "without-abstract";

  const layoutTable = useCallback(() => {
    if (
      scroller.current !== null &&
      content.current !== null &&
      channel.current !== null &&
      bar.current !== null
    ) {
      const scrollTop = scroller.current.scrollTop;
      const scrollHeight = scroller.current.offsetHeight;
      const contentHeight = content.current.offsetHeight;
      const mod = scroller.current.offsetWidth > 620 ? 4 : 3;
      const scrollPercent = scrollTop / (contentHeight - scrollHeight + mod);
      const barHeight = (scrollHeight / contentHeight) * scrollHeight;
      const barOffset = (scrollHeight - barHeight - mod) * scrollPercent;

      bar.current.style.height = `${Math.round(barHeight)}px`;
      bar.current.style.marginTop = `${Math.round(barOffset)}px`;
      channel.current.style.opacity = contentHeight > scrollHeight ? "1" : "0";
      channel.current.style.pointerEvents =
        contentHeight > scrollHeight ? "all" : "none";

      const r = contentHeight > scrollHeight ? 8 : 0;
      if (r !== right) setRight(r);
    }
  }, [right]);

  const tick = useCallback(() => {
    layoutTable();
    if (Date.now() - startTime.current < 260) {
      requestAnimationFrame(tick);
    }
  }, [layoutTable]);

  const animateLayoutTable = useCallback(() => {
    startTime.current = Date.now();
    requestAnimationFrame(tick);
  }, [tick]);

  const toggle = (index: number): void => {
    if (opened.indexOf(index) < 0) {
      setOpened([...opened, index]);
    } else {
      setOpened([...opened].filter((e) => e !== index));
    }
  };

  useEffect(() => {
    if (scroller.current !== null)
      scroller.current.addEventListener("scroll", animateLayoutTable);

    window.addEventListener("resize", animateLayoutTable);

    startTime.current = Date.now();
    requestAnimationFrame(tick);

    return () => {
      window.removeEventListener("resize", layoutTable);
    };
  }, [animateLayoutTable, layoutTable, tick]);

  if (prevCategories.current !== categories) {
    prevCategories.current = categories;
    layoutTable();
  }

  if (prevOpened.current !== opened) {
    prevOpened.current = opened;
    animateLayoutTable();
  }

  const onDown = ({ py }: Position): void => {
    y0.current = py;
  };

  const onMove = ({ py }: Position): void => {
    if (scroller.current !== null) {
      const offset = py - y0.current;
      scroller.current.scrollTop += offset * scroller.current.scrollHeight;
      y0.current = py;
    }
  };

  const onUp = (): void => {};

  return (
    <div className={styles.posters} id="posters">
      <div className={styles.title}>Eisai Posters and Presentations</div>

      <div className={styles.table}>
        <div className={styles.headers}>
          <div className={styles.content}>CONTENT</div>
          <div className={styles[`drawer-${suffix}-true`]}>
            <div className={styles.authors}>AUTHORS</div>
          </div>
        </div>

        <div className={styles.scroller} ref={scroller}>
          <div className={styles.scrolled} ref={content}>
            {React.Children.toArray(
              Object.values(categories).map((category) => {
                const { name, color } = category;
                return (
                  <div className={styles.category}>
                    {name !== "default" && (
                      <div
                        className={styles.name}
                        style={{ backgroundColor: color }}
                      >
                        {name}
                      </div>
                    )}

                    {React.Children.toArray(
                      category.posters.map((poster, index) => {
                        const bool = opened.indexOf(index) > -1;
                        const mod = name !== "default" ? 0.3 : 1.0;

                        return (
                          <div className={styles.poster}>
                            {poster.study !== undefined && (
                              <div
                                className={styles.study}
                                style={{
                                  backgroundColor: hexToRGB(color, mod),
                                }}
                              >
                                <div className={styles.label}>
                                  {poster.study}
                                  {poster.phase !== undefined &&
                                    poster.phase !== "MOCK" && (
                                      <>
                                        {" "}
                                        <span className={styles.pipe}>
                                          {" "}
                                          |&nbsp;
                                        </span>
                                        Phase&nbsp;
                                        {poster.phase}
                                      </>
                                    )}

                                  {poster.phase === "MOCK" && (
                                    <>
                                      {" "}
                                      <span className={styles.pipe}>
                                        {" "}
                                        |&nbsp;
                                      </span>
                                      [ Phase ]
                                    </>
                                  )}
                                </div>

                                <button
                                  className={styles[`toggle-${bool}`]}
                                  style={{ right }}
                                  onClick={() => toggle(index)}
                                >
                                  <img src={chevron} alt="chevron" />
                                </button>
                              </div>
                            )}

                            <div className={styles.row}>
                              <div className={styles.content}>
                                {poster.compounds !== undefined && (
                                  <b className={styles.compounds}>
                                    {poster.compounds}
                                  </b>
                                )}

                                <p className={styles.summary}>
                                  {parse(poster.summary)}
                                </p>
                              </div>

                              <div className={styles[`drawer-${bool}`]}>
                                <div className={styles.authors}>
                                  {parse(poster.authors)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className={styles.channel} ref={channel}>
          <div className={styles.bar} ref={bar} />

          <Gestures
            className={styles.gestures}
            onDown={onDown}
            onMove={onMove}
            onUp={onUp}
          />
        </div>
      </div>
    </div>
  );
};

export default Posters;
