import React from "react";
import styles from "./index.module.scss";
import n0 from "../../images/phases/0.svg";
import n1 from "../../images/phases/1.svg";
import n2 from "../../images/phases/2.svg";
import n3 from "../../images/phases/3.svg";
import n4 from "../../images/phases/4.svg";
import n5 from "../../images/phases/5.svg";
import n6 from "../../images/phases/6.svg";
import n7 from "../../images/phases/7.svg";
import n8 from "../../images/phases/8.svg";
import n9 from "../../images/phases/9.svg";

const arr = [n0, n1, n2, n3, n4, n5, n6, n7, n8, n9];

interface Props {
  value: string;
  height: number;
}

const getWidth = (n: number, h: number) => {
  if (n === 0) return Math.round(h * 0.7);
  if (n === 1) return Math.round(h * 0.44);
  if (n === 2) return Math.round(h * 0.73);
  if (n === 3) return Math.round(h * 0.72);
  if (n === 4) return Math.round(h * 0.75);
  if (n === 5) return Math.round(h * 0.73);
  if (n === 6) return Math.round(h * 0.7);
  if (n === 7) return Math.round(h * 0.68);
  if (n === 8) return Math.round(h * 0.73);
  if (n === 9) return Math.round(h * 0.72);
  return h * 0.5;
};

const PhaseNumber: React.FC<Props> = ({ value, height }: Props) => {
  const number = parseInt(value, 10);
  const width = getWidth(number, height);

  return (
    <img
      className={styles.number}
      style={{ height, width }}
      src={arr[number]}
      alt={value}
    />
  );
};

export default PhaseNumber;
