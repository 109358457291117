import React from "react";
import Header from "../Header";
import styles from "./index.module.scss";
import phone from "../../images/phone.svg";
import email from "../../images/email.svg";

interface Props {
  setNavURL: (url?: string) => void;
}

const PRIVACY_POLICY = "https://www.eisai.eu/privacy-policy/";

const Resources: React.FC<Props> = ({ setNavURL }: Props) => {
  return (
    <div className={styles.contact} id="contact">
      <Header>CONTACT</Header>

      <div className={styles.disclaimer}>
        If you wish to contact Eisai, please reach out via phone number or email
        address listed below. Eisai will use your information to contact you and
        will store your information in accordance with the EU General Data
        Protection Regulation. For more information on how Eisai uses your
        personal data and your rights (including your right to withdraw your
        consent to the processing of your personal data) please see{" "}
        <button onClick={() => setNavURL(PRIVACY_POLICY)}>
          <u>{PRIVACY_POLICY}</u>
        </button>
      </div>

      <div className={styles.copy}>
        <div className={styles.left}>
          <div className={styles.info}>
            <div className={styles.h2}>For more information</div>
            <div className={styles.directions}>
              contact Eisai Medical Information in your region.
            </div>
            <div className={styles.directions}>
              Also for more information, visit{" "}
              <button
                className={styles.trials}
                onClick={() => setNavURL("http://clinicaltrials.gov")}
              >
                ClinicalTrials.gov
              </button>
              .
            </div>
          </div>
        </div>

        <div className={styles.breaker} />

        <div className={styles.right}>
          <div className={styles.h3}>
            In Europe, the Middle East and Africa (EMEA):
          </div>
          <a className={styles.link} href="tel:+44 (0)208 600 1400">
            <img className={styles.icon} src={phone} alt="phone-global" />
            <div className={styles.details}>+44 (0)208 600 1400</div>
          </a>

          <a className={styles.link} href="mailto:EUMedlnfo@eisai.net">
            <img className={styles.icon} src={email} alt="email-global" />
            <div className={styles.details}>EUMedlnfo@eisai.net</div>
          </a>

          <div className={styles.spacer} />

          <div className={styles.h3}>In North or South America:</div>
          <a className={styles.link} href="tel:1-888-274-2378">
            <img className={styles.icon} src={phone} alt="phone-america" />
            <div className={styles.details}>1-888-274-2378</div>
          </a>

          <a className={styles.link} href="mailto:ESI_OncMedInfo@eisai.com">
            <img className={styles.icon} src={email} alt="email-america" />
            <div className={styles.details}>ESI_OncMedInfo@eisai.com</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resources;
