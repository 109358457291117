import React, { useContext } from "react";
import { Category, Card } from "Types";
import { ScrollContext } from "../ScrollContext";
import { studies } from "Content";
import CatCell from "./Category";
import styles from "./index.module.scss";

interface Props {
  onCard: (card: Card) => void;
}

const Studies: React.FC<Props> = ({ onCard }: Props) => {
  const { onToggle } = useContext(ScrollContext);

  return (
    <div className={styles.icon}>
      {React.Children.toArray(
        studies.map((category: Category) => (
          <CatCell category={category} onToggle={onToggle} onCard={onCard} />
        ))
      )}
    </div>
  );
};

export default Studies;
