import React from "react";
import styles from "./index.module.scss";

interface Props {
  studies: number;
}

const getWidth = (n: number) => {
  if (n < 10) return 0;
  return 4;
};

const PhaseSpacer: React.FC<Props> = ({ studies }: Props) => {
  const width = getWidth(studies);
  return <div className={styles.spacer} style={{ width }} />;
};

export default PhaseSpacer;
