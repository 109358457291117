window.dataLayer = window.dataLayer || [];

export const trackCardView = (card) => {
  const category = "Card";
  const action = "View";
  const label = card.replace(/^.*[\\/]/, "");

  window.gtag("event", "Card", {
    event_category: category,
    event_action: action,
    event_label: label,
  });
};

export const trackPoster = (poster) => {
  window.gtag("event", "Poster", {
    event_category: "Poster",
    event_action: "Click",
    event_label: poster,
  });
};

export const trackBanner = (banner) => {
  window.gtag("event", "Banner", {
    event_category: "Banner",
    event_action: "Click",
    event_label: banner,
  });
};
