import React, { useEffect, useRef, useState } from "react";
import { Card } from "Types";
import { ScrollProvider } from "./ScrollContext";
import Studies from "./Studies";
import Agents from "./Agents";
import styles from "./index.module.scss";

interface Props {
  onCard: (card: Card) => void;
}

const Pipeline: React.FC<Props> = ({ onCard }: Props) => {
  const [isLeft, setLeft] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const tapLeft = () => setLeft(true);
  const tapRight = () => setLeft(false);
  const isResizing = useRef(false);
  const indiClass = isLeft ? styles.indiLeft : styles.indiRight;
  const shouldAnimate = width < 768 && !isResizing.current;
  const position = isLeft || width > 768 ? "left" : "right";
  const scrollerClass = styles[`scroller-${position}-${shouldAnimate}`];
  isResizing.current = false;

  useEffect(() => {
    window.addEventListener("resize", () => {
      isResizing.current = true;
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={styles.pipeline}>
      <div className={styles.container}>
        <div className={styles.headers}>
          <button className={styles.header} onClick={tapLeft}>
            <div className={styles.heading}>
              CLINICAL TRIAL <br />
              NAVIGATOR
            </div>
            <div className={styles.description}>
              <span className={styles.bracket}>[</span>
              EXPLORE BY TUMOR TYPE
              <span className={styles.bracket}>]</span>
            </div>
          </button>

          <div className={styles.spacer} />

          <button className={styles.header} onClick={tapRight}>
            <div className={styles.heading}>
              AGENTS UNDER <br /> INVESTIGATION
            </div>
            <div className={styles.description}>
              <span className={styles.bracket}>[</span>
              OVERVIEWS
              <span className={styles.bracket}>]</span>
            </div>
          </button>

          <div className={indiClass} />
        </div>

        <div className={styles.explorers}>
          <div className={scrollerClass}>
            <ScrollProvider>
              <Studies onCard={onCard} />
            </ScrollProvider>

            <div className={styles.spacer} />

            <ScrollProvider>
              <Agents onCard={onCard} />
            </ScrollProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pipeline;
