import React, { createRef, useEffect, useState } from "react";
import { Card } from "Types";
import { scrollToId } from "Scroller";
import { AddJumpListener } from "Events";
import Title from "../Title";
import Menu from "../Menu";
import BackToTop from "../BackToTop";
import Hero from "../Hero";
import Mission from "../Mission";
import PipelinePhases from "../PipelinePhases";
import Pipeline from "../Pipeline";
// import MOA from "../MOA";
import TrialLocations from "../TrialLocations";
import Resources from "../Resources";
import Contact from "../Contact";
import Footer from "../Footer";
import Lightbox from "../Lightbox";
// import Video from "../Video";
import HCPAlert from "../HCPAlert";
import NavAlert from "../NavAlert";
import styles from "./index.module.scss";
import "Cache";

const App: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const [card, setCard] = useState<Card | undefined>(undefined);
  // const [video, setVideo] = useState<KalturaVideo | undefined>(undefined);
  const [navURL, setNavURL] = useState<string | undefined>(undefined);
  const titleRef = createRef<HTMLDivElement>();

  const toggleMenu = (): void => {
    setMenu(!menu);
  };

  // const closeVideo = () => {
  //   setVideo(undefined);
  // };

  useEffect(() => {
    AddJumpListener((id: string) => {
      setTimeout(() => {
        scrollToId(id);
      }, 20);
    });
  }, []);

  return (
    <div className={styles.app}>
      <Title
        titleRef={titleRef}
        menuIsVisible={menu}
        toggleMenu={toggleMenu}
        setNavURL={setNavURL}
      />

      <Menu
        titleRef={titleRef}
        isVisible={menu}
        toggleMenu={toggleMenu}
        setNavURL={setNavURL}
      />

      <div className={styles.scroller} id="scroller">
        <div className={styles.content}>
          <Hero />

          <Mission />
          <PipelinePhases onCard={setCard} />
          <Pipeline onCard={setCard} />
          {/* <MOA onVideo={setVideo} /> */}
          <hr />

          <TrialLocations />
          <Resources />
          <hr />
          <Contact setNavURL={setNavURL} />
          <Footer />

          <BackToTop />
        </div>
      </div>

      <Lightbox card={card} close={() => setCard(undefined)} />
      {/* <Video video={video} close={closeVideo} /> */}
      <HCPAlert />
      <NavAlert navURL={navURL} setNavURL={setNavURL} />
    </div>
  );
};

export default App;
