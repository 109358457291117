import React from "react";
import { dates } from "Content";
import styles from "./index.module.scss";
import eisai from "Images/eisai.svg";

const Footer: React.FC = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.disclaimer}>
				<div>
					All agents discussed in this piece remain unlicensed in the field or market
					of their investigation. This information is not intended to convey details
					of each agent’s clinical efficacy or safety prior to European Medicines
					Agency (EMA), Medicines &#38; Healthcare products Regulatory Agency (MHRA),
					European Commission (EC), and US Food and Drug Administration (FDA)
					approval. There is no guarantee in any market that any of these agents or
					new indications will: successfully complete clinical development, gain
					approval, or be made available commercially. The list may not be
					comprehensive.
				</div>
			</div>

			<div className={styles.signoff}>
				<img src={eisai} className={styles.logo} alt="logo" />
				<div className={styles.breaker} />
				<div className={styles.copy}>
					<span>©&nbsp;{dates.copyright}&nbsp;Eisai&nbsp;Inc.</span>
					<span>All&nbsp;rights&nbsp;reserved.</span>
					<span>{dates.signoff}</span>
					<span>{dates.code}</span>
					<div className={styles.breaker} />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
