const duration = 600;
let startY = 0;
let endY = 0;
let startTime = 0;

const inOutQuad = (n: number) => {
  n *= 2;
  if (n < 1) return 0.5 * n * n;
  return -0.5 * (--n * (n - 2) - 1);
};

const tick = () => {
  const scroller = document.getElementById("scroller");
  const now = Date.now();
  if (now - startTime < duration) {
    let p = (now - startTime) / duration;
    let ease = inOutQuad(p);
    let y = startY + (endY - startY) * ease;
    if (scroller !== null) scroller.scrollTop = y;
    requestAnimationFrame(tick);
  } else {
    if (scroller !== null) scroller.scrollTop = endY;
  }
};

const start = (y0: number, y1: number): void => {
  startY = y0;
  endY = y1;

  startTime = Date.now();
  tick();
};

export const scrollToTop = () => {
  const scroller = document.getElementById("scroller");
  if (scroller !== null) start(scroller.scrollTop, 0);
};

export const scrollToId = (id: string) => {
  const scroller = document.getElementById("scroller");
  const target = document.getElementById(id);

  if (scroller !== null && target !== null) {
    var { y: sy } = scroller.getBoundingClientRect();
    var { y } = target.getBoundingClientRect();
    start(scroller.scrollTop, y - sy + scroller.scrollTop);
  }
};
