import React from "react";
import styles from "./index.module.scss";

const Hero: React.FC = () => {
  return (
    <div className={styles.mission}>
      <h2>
        Eisai’s <i>human health care</i> Mission
      </h2>
      <p>
        <i>
          <b>human health care</b>
        </i>{" "}
        is our goal. We give our first thoughts to patients and their families
        and helping to increase the benefits health care provides.
      </p>
      <h3>Public Awareness Statement</h3>
      <p>
        During these unprecedented times, our{" "}
        <i>
          <b>human health care</b>
        </i>{" "}
        mission is as important as ever. At Eisai, we recognise the mental and
        physical challenges cancer patients and their loved ones face each day
        –– more so now due to the ongoing public health safety concerns related
        to the COVID-19 pandemic. It is, therefore, our responsibility to share
        our research and development programs through online channels, so that
        you have the access you need to our clinical trials under investigation.
      </p>
    </div>
  );
};

export default Hero;
