import React from "react";
import { Category, Phase, Study, Card } from "Types";
import StudyCell from "../Study";
import styles from "./index.module.scss";

interface Props {
  category: Category;
  phase: Phase;
  onCard: (card: Card) => void;
}

const PhaseCell: React.FC<Props> = ({ category, phase, onCard }: Props) => {
  const { phase: catPhase } = category;
  return (
    <div className={styles.phase}>
      <div className={styles.row} style={{ backgroundColor: catPhase }}>
        <div className={styles.phaseTitle}>{`Phase ${phase.level}`}</div>
      </div>

      {React.Children.toArray(
        phase.studies.map((study: Study) => (
          <StudyCell category={category} study={study} onCard={onCard} />
        ))
      )}
    </div>
  );
};

export default PhaseCell;
