import React from "react";
import styles from "./index.module.scss";

interface Props {
  color: string;
  opacity: number;
}

const Icon: React.FC<Props> = ({ color, opacity }: Props) => (
  <div className={styles.centered}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      x="0px"
      y="0px"
      className={styles.icon}
    >
      <g data-name="Group" opacity={opacity}>
        <path
          data-name="Path"
          d="M88.7,12.7v-.4h-.1l-.6-.6H65.1a2,2,0,0,0,0,4H81.9L42.6,54.6a2,2,0,1,0,2.8,2.8L84.8,18.1V34.9a2,2,0,0,0,4,0V13.2a2,2,0,0,0,0-.4Z"
          fill={color}
        />
        <path
          data-name="Path"
          d="M21.2,88.8H68.6a10,10,0,0,0,10-10V50.5a2,2,0,0,0-4,0V78.8a6,6,0,0,1-6,6H21.2a6,6,0,0,1-6-6V31.4a6,6,0,0,1,6-6H49.5a2,2,0,0,0,0-4H21.2a10,10,0,0,0-10,10V78.8A10,10,0,0,0,21.2,88.8Z"
          fill={color}
        />
      </g>
    </svg>
  </div>
);

export default Icon;
