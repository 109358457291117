import React from "react";
import parse from "html-react-parser";
import { Agent, Card } from "Types";
import { agents } from "Content";
import { images } from "Cache";
import styles from "./index.module.scss";

interface Props {
  onCard: (card: Card) => void;
}

const Agents: React.FC<Props> = ({ onCard }: Props) => {
  return (
    <div className={styles.container}>
      {React.Children.toArray(
        agents.map((agent: Agent) => (
          <div
            className={agent.card !== undefined ? styles.cell : styles.disabled}
            onClick={() => {
              if (agent.card !== undefined) onCard(agent.card);
            }}
          >
            <div className={styles.agent}>
              <img
                className={styles.image}
                src={images[agent.icon]}
                alt="agent"
              />
              <div className={styles.info}>
                {agent.brand && <b>{agent.brand}</b>}
                {agent.brand && <span className={styles.pink}> | </span>}
                {parse(agent.name)}
                <br />
                Clinical Trials
                {agent.trials > 0 && (
                  <>
                    <span className={styles.pink}> (</span>
                    {agent.trials}
                    <span className={styles.pink}>)</span>
                  </>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Agents;
