import React, { useRef, useState } from "react";
import { Card, PhaseOverview } from "Types";
import { useBodySize } from "Hooks";
import Number from "./number";
import Spacer from "./spacer";
import styles from "./index.module.scss";
import bg from "Images/phases/bg.svg";

interface Props {
  phase: PhaseOverview;
  children?: React.ReactNode;
  onCard: (card: Card) => void;
}

const Phase: React.FC<Props> = ({ phase, children, onCard }: Props) => {
  useBodySize();
  const studies = `${phase.studies}`.split("");
  const circle = useRef<HTMLImageElement | null>(null);
  const [frame, setFrame] = useState({ top: 60, height: 60 });

  if (circle.current !== null) {
    const w = circle.current.offsetWidth;
    const top = Math.round(w * 0.39);
    const height = Math.round(w * 0.35);

    if (height !== frame.height) {
      setFrame({ top, height });
    }
  }

  const { top, height } = frame;
  return (
    <button className={styles.phase} onClick={() => onCard(phase.card)}>
      <img className={styles.bg} src={bg} ref={circle} alt="phase circle" />
      <div className={styles.title}>{phase.title}</div>
      <div className={styles.studies} style={{ top, height }}>
        {React.Children.toArray(
          studies.map((study, i) => {
            return (
              <>
                <Number height={height} value={study} />

                {i < studies.length - 1 && <Spacer studies={phase.studies} />}
              </>
            );
          })
        )}
      </div>

      {children}
    </button>
  );
};

export default Phase;
