import { AppContent, Banner, Show } from "../types";
import json from "./content.json";

const content: AppContent = json;

export const dates = content.dates;
export const links = content.links;
export const banners = content.banners;
// export const brochure = content.brochure;
export const locations = content.locations;
// export const moas = content.moas;
export const phases = content.phases;
export const agents = content.agents;
export const studies = content.studies;
export const shows = content.shows;

export const defaultBanner: Banner = {
  imageKey: "default",
  title: "Eisai Oncology Clinical&nbsp;Trials",
  description:
    "We are driven to pursue innovative solutions to address cancers with unmet&nbsp;needs",
};

export const getShowInURL = (): string =>
  window.location.pathname.replace("/", "");

export const getShow = (): Show => {
  const showInURL = getShowInURL();
  if (showInURL === undefined || shows[showInURL] === undefined) return {};
  return shows[showInURL];
};

export const getBannerImages = (key: string) => banners[key];
