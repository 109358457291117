import React, { useState } from "react";
import { Category, Study, Card } from "Types";
import Combos from "./combos";
import Icon from "./icon";
import styles from "./index.module.scss";

interface Props {
  category: Category;
  study: Study;
  onCard: (card: Card) => void;
}

const StudyCell: React.FC<Props> = ({ category, study, onCard }: Props) => {
  const { dark, bg, down } = category;
  const [backgroundColor, setBackgroundColor] = useState(bg);

  const onDown = (): void => {
    setBackgroundColor(down);
  };

  const onUp = (): void => {
    setBackgroundColor(bg);
  };

  return (
    <div
      className={styles.cell}
      style={{ backgroundColor }}
      onMouseOver={onDown}
      onMouseEnter={onDown}
      onMouseLeave={onUp}
      onClick={() => onCard(study.card)}
    >
      <div className={styles.study}>
        <Combos
          color={dark}
          opacity={study.compounds.indexOf("and") > -1 ? 1 : 0}
        />
        <div className={styles.info}>
          <b>{study.compounds}</b> {study.summary}
          <br />
          {`ClinicalTrials.gov Identifier: ${study.id}`}
          {study.status && <i> {study.status}</i>}
          {study.study && <div className={styles.code}>{study.study}</div>}
        </div>

        <Icon color={dark} opacity={0.5} />
      </div>
    </div>
  );
};

export default StudyCell;
