import React, { useEffect, useState } from "react";
import { scrollToTop } from "Scroller";
import styles from "./index.module.scss";
import icon from "../../images/back-to-top.svg";

const BackToTop: React.FC = () => {
  const [className, setClassName] = useState(styles.hidden);

  useEffect(() => {
    const scroller = document.getElementById("scroller");
    if (scroller !== null) {
      scroller.onscroll = () => {
        setClassName(scroller.scrollTop === 0 ? styles.hidden : styles.button);
      };
    }
  }, []);

  return (
    <button className={className} onClick={scrollToTop}>
      <img src={icon} alt="back to top" />
    </button>
  );
};

export default BackToTop;
