import React, { useEffect, useCallback, useRef, useState } from "react";
import parse from "html-react-parser";
import { Category, Phase, Card } from "Types";
import { AddJumpListener } from "Events";
import { studies as data } from "Content";
import { images } from "Cache";
import PhaseCell from "../Phase";
import styles from "./index.module.scss";
import arrow from "Images/trials-arrow.svg";

interface Props {
  category: Category;
  onToggle: (y: number, animated: boolean) => void;
  onCard: (card: Card) => void;
}

const CategoryCell: React.FC<Props> = ({
  category,
  onToggle,
  onCard,
}: Props) => {
  const [height, setHeight] = useState(0);
  const prevHeight = useRef(-1);
  const container = useRef<HTMLDivElement>(null);
  const phases = useRef<HTMLDivElement>(null);
  let studies = 0;
  const { dark, light, background } = category;

  prevHeight.current = height;

  category.phases.forEach((p: Phase) => {
    studies += p.studies.length;
  });

  const title = `${category.title} (${studies})`;
  const transform = height > 0 ? "rotate(180deg)" : "rotate(0)";

  const toggle = useCallback(
    (animated: boolean): void => {
      if (container.current !== null && phases.current !== null) {
        const phaseHeight = phases.current.offsetHeight;
        onToggle(height > 0 ? 0 : container.current.offsetTop, animated);
        setHeight(height > 0 ? 0 : phaseHeight);
      }
    },
    [height, onToggle]
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (container.current !== null && phases.current !== null) {
        const phaseHeight = phases.current.offsetHeight;
        if (prevHeight.current > 0 && prevHeight.current !== phaseHeight)
          setHeight(phaseHeight);
      }
    });
  }, []);

  useEffect(() => {
    AddJumpListener((id: string) => {
      const matching = data.filter((s) => s.title === id)[0];
      if (matching !== undefined && matching === category && height === 0) {
        toggle(false);
      }
    });
  }, [category, height, studies, toggle]);

  return (
    <div id={category.title} className={styles.category} ref={container}>
      <div
        className={styles.button}
        style={{ backgroundColor: dark }}
        onClick={() => toggle(true)}
      >
        <div className={styles.slider} style={{ backgroundColor: light }} />
        <img
          src={images[background]}
          className={styles.molecule}
          alt="molecule"
        />

        <div className={styles.title}>{parse(title)}</div>
        <div className={styles.arrow} style={{ backgroundColor: dark }}>
          <img src={arrow} style={{ transform }} alt="arrow" />
        </div>
      </div>

      <div className={styles.drawer} style={{ height }}>
        <div className={styles.phases} ref={phases}>
          {React.Children.toArray(
            category.phases.map((phase: Phase) => (
              <PhaseCell category={category} phase={phase} onCard={onCard} />
            ))
          )}
        </div>
      </div>
      <div className={styles[`spacer-${height === 0}`]} />
    </div>
  );
};

export default CategoryCell;
