import React, { useRef } from "react";
import { interpolate } from "d3-interpolate";
import styles from "./index.module.scss";

const INCREMENT = 0.1;

interface Props {
  menuIsVisible?: boolean;
  toggleMenu?: () => void;
}

const attr = (y: number, rotation: number) => {
  return `translate(7.000000, ${y}) rotate(${rotation}) translate(-7.000000, -5.500000) translate(0.000000, 5.000000)`;
};

const MenuButton: React.FC<Props> = ({ menuIsVisible, toggleMenu }: Props) => {
  const top = useRef<SVGAElement>(null);
  const middle = useRef<SVGAElement>(null);
  const bottom = useRef<SVGAElement>(null);
  const visible = useRef(true);
  const ii = useRef(0.0);
  const y1 = useRef(interpolate(0.5, 5.5));
  const y2 = useRef(interpolate(10.5, 5.5));
  const r1 = useRef(interpolate(-360, -315));
  const r2 = useRef(interpolate(0, -45));
  const op = useRef(interpolate(1.0, 0.0));

  const tick = () => {
    if (
      top.current !== null &&
      middle.current !== null &&
      bottom.current !== null
    ) {
      if (visible.current) {
        if (ii.current < 1 - INCREMENT) {
          ii.current += INCREMENT;
          requestAnimationFrame(tick);
        } else {
          ii.current = 1;
        }
      } else {
        if (ii.current > INCREMENT) {
          ii.current -= INCREMENT;
          requestAnimationFrame(tick);
        } else {
          ii.current = 0;
        }
      }

      top.current.setAttribute(
        "transform",
        attr(y1.current(ii.current), r1.current(ii.current))
      );
      middle.current.setAttribute("opacity", `${op.current(ii.current)}`);

      bottom.current.setAttribute(
        "transform",
        attr(y2.current(ii.current), r2.current(ii.current))
      );
    }
  };

  if (menuIsVisible !== undefined) {
    if (visible.current !== menuIsVisible) {
      visible.current = menuIsVisible;
      requestAnimationFrame(tick);
    }
  }

  return (
    <button className={styles[`menu-${menuIsVisible}`]} onClick={toggleMenu}>
      <svg width="12px" height="11px" viewBox="0 0 12 11">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="menu"
            transform="translate(-1.000000, 0.000000)"
            stroke="#00366F"
          >
            <g
              id="Line_30-2"
              ref={top}
              transform="translate(7.000000, 0.500000) rotate(0.000000) translate(-7.000000, -5.500000) translate(0.000000, 5.000000)"
            >
              <line x1="0" y1="0.5" x2="14" y2="0.5" id="Line_30"></line>
            </g>
            <g
              id="Line_31-2"
              ref={middle}
              opacity="1"
              transform="translate(0.000000, 5.000000)"
            >
              <line x1="0" y1="0.5" x2="14" y2="0.5" id="Line_31"></line>
            </g>
            <g
              id="Line_32-2"
              ref={bottom}
              transform="translate(7.000000, 10.500000) rotate(-360.000000) translate(-7.000000, -5.500000) translate(0.000000, 5.000000)"
            >
              <line x1="0" y1="0.5" x2="14" y2="0.5" id="Line_32"></line>
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
};

export default MenuButton;
