import React, { createContext, useCallback, useEffect, useRef } from "react";
import { ScrollProps } from "Types";
import { Gestures, Position } from "Gestures";
import styles from "./index.module.scss";

const DURATION = 250;

export interface ScrollInterface {
  onToggle: (y: number) => void;
}

const defaultValue: ScrollInterface = {
  onToggle: () => {},
};

type Props = { children?: React.ReactNode };

export const ScrollContext = createContext<ScrollInterface>(defaultValue);

export const ScrollProvider: React.ComponentType<Props> = ({
  children,
}: Props) => {
  const scroller = useRef<HTMLDivElement>(null);
  const bar = useRef<HTMLDivElement>(null);
  const isMobile = navigator.userAgent.match(/(iPad|iPhone|iPod|android)/i);

  const startY = useRef(0);
  const endY = useRef(0);
  const startTime = useRef(0);

  const y0 = useRef(0);

  const inOutQuad = (n: number) => {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return -0.5 * (--n * (n - 2) - 1);
  };

  const scrollProps = (): ScrollProps => {
    if (scroller.current !== null) {
      const scrollTop = scroller.current.scrollTop;
      const scrollHeight = scroller.current.scrollHeight;
      const containerHeight = scroller.current.offsetHeight + 9;
      const percent = scrollTop / scrollHeight;
      const height = containerHeight / scrollHeight;
      const display = containerHeight >= scrollHeight ? "none" : "block";
      console.log(display);

      return { percent, height, display };
    }

    return { percent: 0, height: 0, display: "block" };
  };

  const onScroll = useCallback((): void => {
    if (bar.current !== null) {
      const { percent, height, display } = scrollProps();
      bar.current.style.top = `${percent * 100}%`;
      bar.current.style.height = `${height * 100}%`;
      bar.current.style.display = display;
    }
  }, []);

  const checkScrolling = useCallback(() => {
    if (scroller.current !== null) {
      onScroll();
    }
  }, [onScroll]);

  const tick = () => {
    const now = Date.now();
    if (now - startTime.current < DURATION) {
      let p = (now - startTime.current) / DURATION;
      let ease = inOutQuad(p);
      let y = startY.current + (endY.current - startY.current) * ease;
      if (scroller.current !== null) scroller.current.scrollTop = y;
      requestAnimationFrame(tick);
    } else {
      if (scroller.current !== null) scroller.current.scrollTop = endY.current;
    }
    if (scroller.current !== null) {
      onScroll();
    }
  };

  const onToggle = (y: number, animated?: boolean): void => {
    if (scroller.current !== null) {
      if (y > 0) {
        if (animated) {
          console.log("ANIMATED");
          startY.current = scroller.current.offsetTop;
          endY.current = y;
          startTime.current = Date.now();
          tick();
          if (scroller.current !== null) {
            onScroll();
          }
        } else {
          startY.current = scroller.current.offsetTop;
          scroller.current.scrollTop = y;
          if (scroller.current !== null) {
            onScroll();
          }
        }
      } else {
        setTimeout(() => {
          if (scroller.current !== null) {
            onScroll();
          }
        }, 300);
      }
    }
  };

  const onDown = ({ py }: Position): void => {
    y0.current = py;
  };

  const onMove = ({ py }: Position): void => {
    if (scroller.current !== null) {
      const offset = py - y0.current;
      scroller.current.scrollTop += offset * scroller.current.scrollHeight;
      y0.current = py;
    }
  };

  const onUp = (): void => {};

  useEffect(() => {
    if (scroller.current !== null) {
      scroller.current.onscroll = onScroll;

      window.addEventListener("resize", () => {
        onScroll();
      });

      window.addEventListener("load", () => {
        checkScrolling();
      });
    }
  }, [checkScrolling, onScroll]);

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  return (
    <ScrollContext.Provider
      value={{
        onToggle,
      }}
    >
      <div className={styles.explorer}>
        <div className={styles.container}>
          <div className={styles.scroller} ref={scroller}>
            {children}
          </div>
        </div>

        {!isMobile && (
          <div className={styles.barContainer}>
            <Gestures
              className={styles.gestures}
              onDown={onDown}
              onMove={onMove}
              onUp={onUp}
            />
            <div className={styles.bar} ref={bar} />
          </div>
        )}
      </div>
    </ScrollContext.Provider>
  );
};
