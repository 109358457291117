// Defines global events, providing both dispatch and listener methods
import { trackBanner } from "Analytics";

const JUMP_EVENT = "JUMP_EVENT";
// Called from `src/state.tsx` to direct Scroller component to scroll to specified frame/position
export const DispatchJumpEvent = (id: string): void => {
  trackBanner(id);
  document.dispatchEvent(new CustomEvent(JUMP_EVENT, { detail: { id } }));
};

// Called from `src/components/Scroller.tsx` to listen for event dispatched above
export const AddJumpListener = (handler: (id: string) => void): void => {
  document.addEventListener(JUMP_EVENT, ((event: CustomEvent) => {
    handler(event.detail.id);
  }) as EventListener);
};
