import React, { useState } from "react";
import { links } from "Content";
import styles from "./index.module.scss";
import { dates } from "Content";
import hcp from "Images/btn-hcp.svg";
import not from "Images/btn-hcp-not.svg";

const HCPAlert: React.FC = () => {
	const [hasConfirmed, setConfirmed] = useState(false);

	const onNotHCP = () => {
		window.open(links.eisai, "_blank");
	};

	return (
		<div className={styles[`alert-${hasConfirmed}`]}>
			<div className={styles.message}>
				<p>This site is intended for Healthcare Professionals&nbsp;only.</p>
				<p>
					<b>IMPORTANT: </b>this site contains information on molecules in
					development and so are not licensed for&nbsp;use.
				</p>

				<button onClick={() => setConfirmed(true)}>
					<img src={hcp} alt="I Am A Healthcare Professional" />
				</button>

				<button onClick={onNotHCP}>
					<img src={not} alt="I Am Not A Healthcare Professional" />
				</button>

				<p>
					If you have a specific medical information question please e-mail
					<a href="mailto:ESI_OncMedInfo@eisai.com">
						{" "}
						<b>EUmedinfo@eisai.net.</b>
					</a>
				</p>

				<p>
					{dates.hcpDate} <span>{dates.hcpCode}</span>
				</p>
			</div>
		</div>
	);
};

export default HCPAlert;
