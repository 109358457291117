import React, { useRef, useState } from "react";
import { Card } from "Types";
import { cards } from "Cache";
import Loader from "./loader";
import { trackCardView } from "Analytics";
import styles from "./index.module.scss";
import closeX from "Images/card-close.svg";

interface Props {
  card?: Card;
  close: () => void;
}

const Lightbox: React.FC<Props> = ({ card, close }) => {
  const [source, setSource] = useState("");
  const [isLoading, setLoading] = useState(true);
  const graphic = useRef<HTMLImageElement>(null);

  if (card !== undefined && source === "") {
    setLoading(true);
    setSource(card.graphic);
    trackCardView(card.graphic);
  }

  if (card === undefined && source !== "") {
    setTimeout(() => {
      setSource("");
    }, 400);
  }

  const onImageLoad = (): void => {
    setLoading(false);
  };

  return (
    <div className={styles[`lightbox-${card !== undefined}`]}>
      <div className={styles.fader} />
      {isLoading && <Loader />}

      <div className={styles.column}>
        <button className={styles.close} onClick={close}>
          <img src={closeX} alt="close" />
        </button>

        <div className={styles.scroller} id="scroller">
          <div className={styles.content}>
            <div className={styles.card}>
              <img
                src={cards[source]}
                className={isLoading ? styles.graphicLoading : styles.graphic}
                onLoad={onImageLoad}
                ref={graphic}
                alt="card"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lightbox;
