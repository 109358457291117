import React from "react";
import styles from "./index.module.scss";

interface Props {
  children?: React.ReactNode;
}

const Header: React.FC<Props> = ({ children }: Props) => (
  <div className={styles.header}>
    <div className={styles.padded}>{children}</div>
  </div>
);

export default Header;
