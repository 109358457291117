import { useCallback, useEffect, useRef, useState } from "react";

const useBodySize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const prevSize = useRef({ width: 0, height: 0 });
  if (prevSize.current !== size) prevSize.current = size;

  const layout = useCallback(() => {
    const newSize = { width: window.innerWidth, height: window.innerHeight };
    if (newSize !== prevSize.current) setSize(newSize);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", layout);
    window.addEventListener("orientationchange", layout);
    layout();

    return () => {
      window.removeEventListener("resize", layout);
      window.removeEventListener("orientationchange", layout);
    };
  }, [layout]);

  return size;
};

export default useBodySize;
