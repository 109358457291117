import React from "react";
import { dates } from "Content";
import styles from "./index.module.scss";
import btnYes from "Images/btn-yes.svg";
import btnNo from "Images/btn-no.svg";

interface Props {
	navURL?: string;
	setNavURL: (url?: string) => void;
}

const NavAlert: React.FC<Props> = ({ navURL, setNavURL }: Props) => {
	return (
		<div className={styles[`alert-${navURL !== undefined}`]}>
			<div className={styles.message}>
				<p>
					You are now leaving the Eisai website. <br className={styles.mobile} /> Do
					you wish to proceed? <br />
					Please click yes or no.
				</p>

				<button
					onClick={() => {
						setNavURL(undefined);
						setTimeout(() => {
							window.open(navURL, "_blank");
						}, 100);
					}}
				>
					<img src={btnYes} alt="Yes" />
				</button>

				<button
					onClick={() => {
						setNavURL(undefined);
					}}
				>
					<img src={btnNo} alt="no" />
				</button>
				<div className={styles.signoff}>
					<span>{dates.warningSignoff}</span>
					<span>{dates.warningCode}</span>
				</div>
			</div>
		</div>
	);
};

export default NavAlert;
