import React, { useState } from "react";
import { useBodySize } from "Hooks";
import { scrollToId } from "Scroller";
import { getShow, getShowInURL, links } from "Content";
import styles from "./index.module.scss";
import homeIcon from "Images/home.svg";
import trials from "Images/trials.svg";
import contact from "Images/contact.svg";
import poster from "Images/posters.svg";
import homeWhite from "Images/home-white.svg";
import trialsWhite from "Images/trials-white.svg";
import contactWhite from "Images/contact-white.svg";
import posterWhite from "Images/posters-white.svg";

interface Props {
  titleRef: React.RefObject<HTMLDivElement>;
  isVisible?: boolean;
  toggleMenu?: () => void;
  setNavURL: (url?: string) => void;
}

const Menu: React.FC<Props> = ({
  titleRef,
  isVisible,
  toggleMenu,
  setNavURL,
}: Props) => {
  useBodySize();
  const [top, setTop] = useState(0);
  const vis = isVisible || false;
  const show = getShow();
  const posters = show.posters || [];
  const showInURL = getShowInURL();
  const showHome = showInURL === "" ? links.home : `${links.home}/${showInURL}`;

  if (titleRef.current !== null) {
    const height = titleRef.current.offsetHeight;
    if (top !== height) setTop(height);
  }

  const onContact = (): void => {
    if (toggleMenu !== undefined) toggleMenu();
    scrollToId("contact");
  };

  const onPosters = (): void => {
    if (toggleMenu !== undefined) toggleMenu();
    scrollToId("resources");
  };

  const onNav = (url?: string): void => {
    if (toggleMenu !== undefined) toggleMenu();
    setNavURL(url);
  };

  return (
    <div className={styles.menu} style={{ top }}>
      <div className={styles[`menu-${vis}`]}>
        <button className={styles.link} onClick={() => onNav(showHome)}>
          <img src={homeIcon} className={styles.icon} alt="home" />
          <img src={homeWhite} className={styles.white} alt="home" />
        </button>

        <button className={styles.link} onClick={() => onNav(links.trials)}>
          <img src={trials} className={styles.icon} alt="trials" />
          <img src={trialsWhite} className={styles.white} alt="trials" />
        </button>

        <button className={styles.link} onClick={onContact}>
          <img src={contact} className={styles.icon} alt="contact" />
          <img src={contactWhite} className={styles.white} alt="contact" />
        </button>

        {posters.length > 0 && (
          <button className={styles.link} onClick={onPosters}>
            <img src={poster} className={styles.icon} alt="contact" />
            <img src={posterWhite} className={styles.white} alt="contact" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Menu;
