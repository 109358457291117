import React from "react";
import { getShow } from "Content";
import Header from "../Header";
import styles from "./index.module.scss";
import Posters from "../Posters";

const Resources: React.FC = () => {
  const show = getShow();
  const posters = show.posters || {};
  return (
    <>
      {Object.keys(posters).length > 0 && (
        <>
          <hr />{" "}
          <div className={styles.resources} id="resources">
            <Header>RESOURCES</Header>
            <Posters categories={posters} />
          </div>
        </>
      )}
    </>
  );
};

export default Resources;
