import React from "react";
import { Card } from "Types";
import { phases, dates } from "Content";
import Header from "../Header";
import Phase from "../Phase";
import styles from "./index.module.scss";

interface Props {
  onCard: (card: Card) => void;
}

const PipelinePhases: React.FC<Props> = ({ onCard }: Props) => (
  <div className={styles.pipelineOverview}>
    <Header>EISAI'S ONCOLOGY PIPELINE OVERVIEW*</Header>

    <div className={styles.inner}>
      <div className={styles.phases}>
        <Phase phase={phases[0]} onCard={onCard} />
        <Phase phase={phases[1]} onCard={onCard} />
        <div className={styles.breaker} />

        <Phase phase={phases[2]} onCard={onCard}>
          <div className={styles.footer}>
            <div className={styles.ref}>
              *Active, recruiting trials
              <br /> as of {dates.phases}
            </div>
          </div>
        </Phase>

        <Phase phase={phases[3]} onCard={onCard}>
          <div className={styles.footer}>
            <div className={styles.ref}>
              <span className={styles.superscript}>†</span>Assumes total number
              of trials only;
              <br /> does not include studies that may <br />
              overlap between phase 1-3
            </div>
          </div>
        </Phase>

        <div className={styles.footerSmall}>
          <div className={styles.ref}>
            *Active, recruiting trials as of {dates.phases}
          </div>
          <div className={styles.ref}>
            <span className={styles.superscript}>†</span>Assumes total number of
            trials only; does not include studies&nbsp;that may overlap between
            phase 1-3
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PipelinePhases;
