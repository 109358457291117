import React from "react";
import { locations, dates } from "Content";
import Header from "../Header";
import Bullets from "./bullets";
import styles from "./index.module.scss";
import map from "Images/locations.jpg";

const TrialLocations: React.FC = () => {
  return (
    <div className={styles.locations}>
      <Header>
        EISAI STUDIES ARE BEING CONDUCTED <br className="mobile" /> AT{" "}
        <br className="desktop" />
        CLINICAL TRIAL SITE LOCATIONS <br className="mobile" /> AROUND THE GLOBE
        <sup>‡</sup>
      </Header>

      <img className={styles.map} src={map} alt="map" />

      <div className={styles.copy}>
        <div className={styles.column}>
          <Bullets location={locations[0]} />
        </div>

        <div className={styles.column}>
          <Bullets location={locations[1]} />

          <br />

          <Bullets location={locations[2]} />
        </div>

        <div className={styles.column}>
          <Bullets location={locations[3]} />
        </div>

        <div className={styles.inset}>
          <p>
            <sup>‡</sup>Eisai clinical trial site locations current as of{" "}
            {dates.locations}. List may not be comprehensive.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrialLocations;
