import React, { useCallback, useEffect, useState } from "react";
import parse from "html-react-parser";
import { getShow, defaultBanner, getBannerImages } from "Content";
import { DispatchJumpEvent } from "Events";
import { images } from "Cache";
import { useBodySize } from "Hooks";
import styles from "./index.module.scss";
import learn from "Images/btn-learn-more.svg";
import arrow from "Images/btn-arrow-left.svg";
let timeoutId: ReturnType<typeof setTimeout>;

const Hero: React.FC = () => {
  const [index, setIndex] = useState(0);
  const { width } = useBodySize();
  const show = getShow();
  const banners = show.banners || [defaultBanner];

  const prev = () => {
    setIndex(index > 0 ? index - 1 : banners.length - 1);
  };

  const next = useCallback(() => {
    setIndex(index < banners.length - 1 ? index + 1 : 0);
  }, [banners.length, index]);

  const transform = `translateX(-${index * 100}%)`;

  useEffect(() => {
    if (banners.length > 1) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        next();
      }, 5500);
    }
  }, [banners.length, next]);

  return (
    <div className={styles.hero}>
      <div className={styles.banners} style={{ transform }}>
        {React.Children.toArray(
          banners.map(({ imageKey, title, description, color: c, jump }, i) => {
            const { desktop, mobile } = getBannerImages(imageKey);
            const di = `url(${images[desktop]})`;
            const mi = `url(${images[mobile]})`;
            const color = c || "#00366f";

            return (
              <div className={styles.banner}>
                <div
                  className={styles.desktop}
                  style={{ backgroundImage: di }}
                />
                <div
                  className={styles.mobile}
                  style={{ backgroundImage: mi }}
                />

                <div className={styles.copy} style={{ color }}>
                  <div className={styles.inner}>
                    <div className={styles.title}>{parse(title)}</div>
                    <div className={styles.description}>
                      {parse(description)}
                    </div>

                    {(jump !== undefined || banners.length > 1) && (
                      <div className={styles.bottom}>
                        {jump !== undefined && (
                          <button
                            className={styles.learn}
                            onClick={() => DispatchJumpEvent(jump)}
                          >
                            <img src={learn} alt="Learn more" />
                          </button>
                        )}

                        {banners.length > 1 && (
                          <div className={styles.dots}>
                            {React.Children.toArray(
                              banners.map((_, j) => {
                                const backgroundColor =
                                  i !== j
                                    ? "transparent"
                                    : width > 600
                                    ? color
                                    : "#00366f";
                                const borderColor = color;
                                return (
                                  <div
                                    className={styles.dot}
                                    style={{ backgroundColor, borderColor }}
                                  />
                                );
                              })
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {banners.length > 1 && (
        <>
          <div className={styles.left}>
            <button onClick={prev} className={styles.arrow}>
              <img src={arrow} alt="arrow" />
            </button>
          </div>

          <div className={styles.right}>
            <button onClick={next} className={styles.arrow}>
              <img src={arrow} alt="arrow" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Hero;
