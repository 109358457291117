import React from "react";
import styles from "./index.module.scss";

interface Props {
  color: string;
  opacity: number;
}

const Combos: React.FC<Props> = ({ color, opacity }: Props) => (
  <div className={styles.combos}>
    <svg width="31px" height="18px" viewBox="0 0 31 18" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity}
      >
        <g id="trial-circles" transform="translate(1.000000, 1.000000)">
          <path
            d="M20.781,16 C16.312,16.043 12.293,12.191 12.381,7.944 C12.463,3.844 16.425,0.035 20.652,0 C25.0342223,0.0466197901 28.6559208,3.43097926 28.999,7.8 C29.141,12.107 25.285,15.955 20.781,16 Z"
            id="Path_121-2"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M20.781,16 C16.312,16.043 12.293,12.191 12.381,7.944 C12.463,3.844 16.425,0.035 20.652,0 C25.0342223,0.0466197901 28.6559208,3.43097926 28.999,7.8 C29.141,12.107 25.285,15.955 20.781,16 Z"
            id="Path_122-2"
            stroke="#FFFFFF"
            strokeWidth="0.372"
          ></path>
          <path
            d="M12.667,1.684 C9.867,6.035 9.913,10.193 12.612,14.284 C8.833,16.884 4.984,16.503 2.038,13.284 C-0.510946102,10.4607818 -0.686196456,6.22199267 1.621,3.198 C4.2,-0.139 8.491,-0.793 12.667,1.684 Z"
            id="Path_123-2"
            stroke="#FFFFFF"
            strokeWidth="0.372"
            fill={color}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  </div>
);
export default Combos;
