import React from "react";
import { Location } from "Types";

interface Props {
  location?: Location;
}

const TrialBullets: React.FC<Props> = ({ location }: Props) => {
  return (
    <>
      {location !== undefined && (
        <>
          <h2>{location.title}</h2>
          <ul>
            {location.countries.map((c) => (
              <li key={c}>{c}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default TrialBullets;
