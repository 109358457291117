import React from "react";
import { scrollToId } from "Scroller";
import { getShow, links } from "Content";
import MenuButton from "./menu-button";
import styles from "./index.module.scss";
import eisai from "Images/eisai.svg";
import home from "Images/home.svg";
import trials from "Images/trials.svg";
import contact from "Images/contact.svg";
import poster from "Images/posters.svg";

interface Props {
  titleRef: React.RefObject<HTMLDivElement>;
  menuIsVisible?: boolean;
  toggleMenu?: () => void;
  setNavURL: (url?: string) => void;
}

const Title: React.FC<Props> = ({
  titleRef,
  menuIsVisible,
  toggleMenu,
  setNavURL,
}: Props) => {
  const show = getShow();
  const posters = show.posters || {};

  return (
    <header className={styles.title} ref={titleRef}>
      <div className={styles.row}>
        <div className={styles.left}>
          <button onClick={() => setNavURL(links.eisai)}>
            <img src={eisai} className={styles.logo} alt="home" />
          </button>
        </div>

        <div className={styles.center}>
          <h2 className={styles.name}>ONCOLOGY RESEARCH &amp; DEVELOPMENT</h2>
          <hr className={styles.rule} />
          <div className={styles.nav}>
            <button
              className={styles.button}
              onClick={() => setNavURL(links.home)}
            >
              <img src={home} className={styles.link} alt="home" />
            </button>

            <button
              className={styles.button}
              onClick={() => setNavURL(links.trials)}
            >
              <img src={trials} className={styles.link} alt="trials" />
            </button>

            <button
              className={styles.button}
              onClick={() => scrollToId("contact")}
            >
              <img src={contact} className={styles.link} alt="contact" />
            </button>

            {Object.keys(posters).length > 0 && (
              <button
                className={styles.button}
                onClick={() => scrollToId("resources")}
              >
                <img src={poster} className={styles.link} alt="posters" />
              </button>
            )}
          </div>
        </div>

        <div className={styles.right} onClick={toggleMenu}>
          <MenuButton menuIsVisible={menuIsVisible} toggleMenu={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default Title;
