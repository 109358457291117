import React, { MouseEvent, TouchEvent, useRef, WheelEvent } from "react";

interface Interaction {
  clientX?: number;
  clientY?: number;
  touches?: React.TouchList;
}

export interface Position {
  x: number;
  y: number;
  px: number;
  py: number;
}

export interface Offset {
  x: number;
  y: number;
}

interface Props {
  useOut?: boolean;
  className: string;
  onDown: (p: Position) => void;
  onMove: (p: Position) => void;
  onUp: (p: Position) => void;
  onWheel?: (offset: Offset) => void;
}

export const Gestures: React.FC<Props> = ({
  useOut,
  className,
  onDown,
  onMove,
  onUp,
  onWheel,
}: Props) => {
  const isDragging = useRef(false);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const prevPosition = useRef<Position>({ x: 0, y: 0, px: 0, py: 0 });

  const eventPosition = (e: Interaction): Position => {
    if (btnRef.current !== null) {
      const {
        left,
        top,
        width,
        height,
      } = btnRef.current.getBoundingClientRect();

      const x0 =
        e.clientX !== undefined
          ? e.clientX
          : e.touches !== undefined
          ? e.touches[0].clientX
          : 0;
      const y0 =
        e.clientY !== undefined
          ? e.clientY
          : e.touches !== undefined
          ? e.touches[0].clientY
          : 0;

      const x = Math.round(x0 - left);
      const y = Math.round(y0 - top);
      const px = Math.round(Math.max(0, Math.min(x / width)) * 1000) / 1000;
      const py = Math.round(Math.max(0, Math.min(y / height)) * 1000) / 1000;

      return { x, y, px, py };
    }

    return { x: 0, y: 0, px: 0, py: 0 };
  };

  const handleDown = (e: MouseEvent | TouchEvent): void => {
    prevPosition.current = eventPosition(e);
    onDown(prevPosition.current);
    isDragging.current = true;
  };

  const handleMove = (e: MouseEvent | TouchEvent): void => {
    if (isDragging.current) {
      prevPosition.current = eventPosition(e);
      onMove(prevPosition.current);
    }
  };

  const handleUp = (): void => {
    onUp(prevPosition.current);
    isDragging.current = false;
  };

  const handleWheel = (e: WheelEvent): void => {
    if (onWheel !== undefined) {
      onWheel({ x: e.deltaX, y: e.deltaY });
    }
  };

  return (
    <button
      className={className}
      ref={btnRef}
      onTouchStart={handleDown}
      onTouchMove={handleMove}
      onTouchEnd={handleUp}
      onMouseDown={handleDown}
      onMouseMove={handleMove}
      onMouseUp={handleUp}
      onMouseOut={useOut ? handleUp : undefined}
      onWheel={handleWheel}
    />
  );
};

Gestures.defaultProps = {
  useOut: true,
} as Partial<Props>;
